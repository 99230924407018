.result {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  border: 1px solid var(--main-color);
  padding: 1rem;
}

.resultImg {
  width: 250px;
  height: 250px;
}

.resultImg img {
  width: 100%;
  height: 100%;
}

.resultData {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.resultData h3 {
  color: var(--main-color);
  font-size: 1.75rem;
}

.resultData p {
  color: #777;
  font-size: 14px;
  line-height: 1.7;
}

.resultData a {
  font-size: 14px;
  padding: 0.25rem 0.75rem;
  align-self: flex-start;
  border: 1px solid var(--main-color);
  transition: 0.3s;
}

.resultData a:hover {
  background-color: var(--main-color);
  color: white;
}

@media (max-width: 768px) {
  .result {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .resultImg {
    width: 100%;
    height: auto;
  }

  .resultData {
    text-align: center;
  }

  .resultData a {
    align-self: unset;
  }
}
