@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
  object-fit: cover;
}

:root {
  --main-color: #f9a612;
  --second-color: #3d3d3d;
  --third-color: #ee2630;
}

body {
  font-family: "Cairo", sans-serif;
}

#root {
  min-height: calc(100dvh);
  display: flex;
  flex-direction: column;
}
