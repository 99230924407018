.st-cont {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .sec-title {
    font-size: 35px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
    position: relative;
    display: inline;
  }
  .sec-title::after {
    content: "";
    width: 70px;
    height: 2px;
    background-color: var(--main-color);
    position: absolute;
    bottom: 8px;
    left: 0;
  }