.tagsTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.TagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 20px;
}

.TagsContainer button {
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  border: 2px solid var(--main-color);
  background: none;
  color: var(--main-color);
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.3s ease;
}

.TagsContainer button:focus,
.TagsContainer button:hover {
  outline: none;
  color: #fff;
  background-color: var(--main-color);
}
