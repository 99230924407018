.scroller {
  max-width: 100%;
  overflow: hidden;
}

.scroller_inner {
  --duplicates: 2;
  --gap: 1.5rem;
  display: flex;
  gap: var(--gap);
  padding-block: 1rem;
  width: max-content;
  justify-content: center;
}

.scroller_inner.still {
  width: auto;
}

.scroller_inner.animated {
  animation: scroll 30s linear infinite;
}

.scroller_inner:hover {
  animation-play-state: paused;
}

@keyframes scroll {
  to {
    transform: translate(
      calc(-100% / var(--duplicates) - calc(var(--gap) / var(--duplicates)))
    );
  }
}
