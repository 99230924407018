.card {
  height: 350px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}
.card img {
  width: 100%;
  height: 100%;
}
.card .bl-ov {
  position: absolute;
  bottom: -20px;
  left: 0;
  background-color: rgba(249, 164, 16, 0.7);
  width: 100%;
  padding: 15px 0;
  padding-bottom: 30px;
  text-align: center;
  transition: 0.3s;
}
.card:hover .bl-ov {
  bottom: 0px;
}
.card a {
  display: block;
  text-transform: uppercase;
  font-size: 15px;
  color: #fff;
  margin-bottom: 0;
}
.card a.p-name {
  font-size: 20px;
  font-weight: 500;
  display: block;
}
