.clients {
  width: 100%;
  padding: 30px 0;
  background-color: #eee;
}
.scrollParent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.scrollParent img {
  height: 50px;
  filter: grayscale(100%);
  transition: 0.3s;
}
.scrollParent img:hover {
  filter: grayscale(0);
}
@media (max-width: 768px) {
  .clients {
    padding: 20px;
  }
}
