.team-member {
  margin-top: 50px;
  background-image: url(../../../assets//Images/cover.jpg);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}
.team-member .overlay {
  background-color: rgba(255, 217, 0, 0.3);
  padding: 50px;
}

.card {
  width: 250px;
  position: relative;
}
.card img {
  aspect-ratio: 1;
  object-fit: cover;
}

.tm-info {
  padding-top: 10px;
  text-align: center;
  color: #fff;
}
.tm-info h4 {
  font-size: 20px;
  text-wrap: nowrap;
}
