.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.content {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.heading {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #333;
}

.message {
  font-size: 1rem;
  color: #555;
}
