.navbar {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  box-shadow: 0 0 15px 8px rgba(0, 0, 0, 0.3);
}
.navCont {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  position: relative;
  height: 90px;
}
.navbar .nav-l {
  display: flex;
  align-items: center;
}
.navbar .nav-l ul {
  margin-right: 10px;
}

@media (min-width: 769px) {
  .navbar .nav-l ul {
    transform-origin: left bottom;
    transition: opacity 0.3s, scale 0.3s, transform 0.3s;
  }
  .navbar .nav-l ul.srch-vis {
    opacity: 0;
    scale: 0 1;
    transform: translateX(-50px);
  }
}

.navbar .nav-l ul {
  display: flex;
  gap: 20px;
  align-items: center;
}
.navbar .nav-l ul li a {
  font-size: 18px;
  color: var(--second-color);
  text-transform: capitalize;
  transition: 0.3s color;
  font-weight: 600;
}
.navbar .nav-l ul li a:hover {
  color: var(--main-color);
}
.navbar .nav-l ul li a.active {
  color: var(--main-color);
  font-weight: 700;
}

@media (min-width: 769px) {
  .navbar .nav-btn {
    display: none;
  }
}

@media (max-width: 768px) {
  .navbar .nav-btn {
    outline: none;
    border: none;
    background-color: var(--main-color);
    padding: 10px;
    border-radius: 7px;
    cursor: pointer;
    color: #fff;
    transition: 0.3s;
    margin-left: 10px;
  }

  .navbar .nav-btn:hover,
  .navbar .nav-btn:focus {
    outline: none;
  }

  .navbar .nav-btn span {
    display: block;
    width: 25px;
    height: 2px;
    background-color: #fff;
    border-radius: 100px;
    margin: 5px;
    transition: 0.3s;
  }

  .nav-btn.active span:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }

  .nav-btn.active span:nth-child(2) {
    opacity: 0;
  }

  .nav-btn.active span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .navbar .nav-l ul {
    display: flex;
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    background-color: #fff;
    width: 100%;
    height: calc(100dvh - 90px);
    z-index: 999998;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
    padding: 20px;
    overflow: auto;
  }

  .navbar .nav-l ul li a {
    font-size: 20px;
    font-weight: bold;
  }
}

.SearchBar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar .nav-l .search-input {
  display: flex;
  align-items: center;
  width: 200px;
  transform-origin: right bottom;
  scale: 0 1;
  width: 0;
  opacity: 0;
  transition: 0.3s;
}

.search {
  border: 0;
  outline: 0;
  border-bottom: 1px solid var(--second-color);
  width: 80%;
  font-size: 18px;
  padding: 10px;
}

.navbar .nav-l .search-input.active {
  width: min(30vw, 200px);
  scale: 1;
  opacity: 1;
}

.navbar .nav-l .search-btn {
  border: none;
  font-size: 18px;
  background-color: var(--main-color);
  padding: 12px 15px;
  margin-left: 10px;
  border-radius: 7px;
  cursor: pointer;
  color: #fff;
  transition: 0.3s;
}
.navbar .nav-l .search-btn:hover {
  background-color: var(--main-color);
}

.navbar .nav-l .search-btn:focus {
  outline: none;
}

.search-input .navbar .nav-r {
  display: flex;
  align-self: flex-start;
}
.navbar .nav-r a img {
  height: 70px;
}
