.blogs {
  padding-block: 75px;
}

.blogs-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 95%), 1fr));
  gap: 15px;
}

.st-cont {
  text-align: center;
}

.st-cont .center-btn {
  font-size: 20px;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  padding: 5px 30px;
  text-transform: capitalize;
  border-radius: 5px;
  transition: 0.3s;
}

.st-cont .center-btn:hover {
  color: #fff;
  background-color: var(--main-color);
}
