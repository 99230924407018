.projects-show {
  padding: 75px 0;
}
.projects-show .pjs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.pjs .project {
  height: 500px;
  position: relative;
}

.pjs .project {
  flex: 1 25%;
}

.pjs .project:first-of-type,
.pjs .project:last-of-type {
  flex: 2 50%;
}
.pjs img {
  width: 100%;
  height: 100%;
}
.pj-detail {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: #fcad26b2;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}
.project:hover .pj-detail {
  opacity: 1;
}
.pd-i {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 20px;
  color: #fff;
}
.pd-i a {
  display: block;
  text-transform: uppercase;
  font-size: 15px;
  color: #fff;
}
.pd-i a.p-name {
  font-size: 18px;
  font-weight: 500;
}
.pd-i span {
  font-size: 18px;
  text-transform: uppercase;
}
.pd-i i {
  margin-right: 10px;
}

@media (max-width: 991px) {
  .pjs .project {
    flex: 1 50%;
  }
  .pjs .project:first-of-type,
  .pjs .project:last-of-type {
    flex: 2 100%;
  }
}

@media (max-width: 767px) {
  .pjs .project {
    flex: 1 100%;
  }
  .pjs .project:first-of-type,
  .pjs .project:last-of-type {
    flex: 1 100%;
  }
}
