.aboutus {
  padding: 75px 0;
}
.aboutCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.ab-r img {
  width: 350px;
  height: 100%;
  object-fit: cover;
}
.ab-l {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 5px;
}
.ab-l p {
  font-size: 18px;
  font-weight: 600;
}

@media only screen and (max-width: 992px) {
  .aboutus {
    padding: 0 20px;
  }
  .ab-l {
    width: 60%;
    margin-left: 0;
  }
  .ab-r {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .aboutus {
    padding: 0 20px;
  }
  .ab-r,
  .ab-l {
    width: 100%;
  }
  .ab-r img,
  .ab-r {
    display: none;
  }
  .ab-l {
    text-align: center;
    margin-left: 0;
  }
}
