.search {
  margin-top: 90px;
}

.resultsTitle {
  font-size: 2rem;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 1.5rem;
}

.resultsTitle span {
  color: var(--main-color);
  text-transform: none;
}

.form {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 70%;
  margin: 0 auto;
}

.form input {
  font-size: 1.2rem;
  border: 1px solid #c6c6c6;
  padding: 10px;
  flex: 1;
  transition: border-color 0.3s ease;
}

.form input:focus,
.form input:hover {
  outline: none;
  border-color: var(--main-color);
}

.form input::placeholder {
  color: #c6c6c6;
}

.form button {
  background-color: var(--main-color);
  color: white;
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  transition: 0.3s ease;
}

.form button:hover {
  filter: brightness(1.1);
}

.form button:hover svg {
  animation: rotate 1s ease-in-out infinite alternate;
}

@keyframes rotate {
  from {
    transform: rotate(0deg) scale(1);
  }
  to {
    transform: rotate(20deg) scale(1.05);
  }
}

.searchCont {
  padding: 50px 15px;
}

.results {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(400px, 95%), 1fr));
  gap: 1.5rem;
}
