.feat-w {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #eee;
    border-bottom: 4px solid var(--main-color);
    cursor: pointer;
    transition: 0.3s;
  }
  .feat-w:hover {
    color: #fff;
    background-color: var(--main-color);
  }
  .feat-w .feat-w-c svg {
    display: block;
    font-size: 50px;
    color: var(--main-color);
    margin-bottom: 20px;
    margin-inline: auto;
  }
  .feat-w:hover svg {
    color: #fff;
  }
  .feat-w .feat-w-c span {
    text-transform: uppercase;
    margin: 0 !important;
    font-size: 15px;
  }
  .feat-w .feat-w-c h3 {
    font-size: 30px;
    font-weight: 500;
    margin-top: -10px !important;
    text-transform: capitalize;
  }
  .feat-w .feat-w-c p {
    font-size: 12px;
    width: 85%;
    margin: auto;
    text-transform: capitalize;
  }