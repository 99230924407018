.image-gallery {
  width: min(500px, 90%);
  height: 500px;
  margin: auto;
  margin-bottom: 130px;
}

.image-gallery .image-gallery-slide {
  height: 500px;
}

.image-gallery .image-gallery-slide img {
  width: 100%;
  aspect-ratio: 1;
  max-height: 80vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}
