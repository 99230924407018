.feat-img {
  position: relative;
}

.feat-img .feat-ov {
  position: absolute;
  top: 0;
  left: -100%;
  background-color: rgba(249, 164, 16, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}
.feat-img:hover .feat-ov {
  left: 0;
}
.feat-ov a {
  font-size: 25px;
  color: #fff;
  font-weight: 500;
  border-bottom: 2px solid #fff;
}

@media (max-width: 768px) {
  .feat-img {
    display: none;
  }
}
