.featsCont {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(370px, 95%), 1fr));
  gap: 15px;
  padding: 75px 15px;
}

.feat {
  height: 370px;
  overflow: hidden;
  cursor: pointer;
}

.feat img {
  width: 100%;
  height: 100%;
}
