.f-contact {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.f-contact form,
.f-contact ul {
  width: 67%;
  background: white;
  padding: 20px;
  box-shadow: 0 6px 11px 6px rgb(0 0 0 / 10%);
}

.f-contact .cust-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.f-contact input,
.f-contact textarea {
  width: 49.5%;
  font-weight: 600;
  font-size: 1rem;
  background-color: transparent;
  outline: 0;
  border: 1px solid var(--second-color);
  padding: 15px;
  margin-bottom: 30px;
}

.f-contact textarea {
  resize: none;
  height: 200px;
  width: 100%;
}

.f-contact .submit {
  color: white;
  font-weight: bold;
  padding: 15px;
  width: 50%;
  background-color: var(--main-color);
  border: 0;
  font-size: 20px;
  cursor: pointer;
}

.f-contact ul {
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.f-contact ul li a {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  color: var(--second-color);
}

.f-contact ul li a svg {
  background-color: var(--main-color);
  color: white;
  width: 50px;
  height: 50px;
  padding: 10px;
}

@media (max-width: 992px) {
  .f-contact form {
    width: 100%;
  }
  .f-contact ul {
    width: 100%;
  }
}
