.postContainer {
  padding: 50px 15px;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.postContent {
  width: 70%;
}

.postTitle {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--main-color);
}

.postBody p {
  line-height: 1.75rem;
  margin-bottom: 20px;
  color: #111;
}

.postBody img {
  margin-bottom: 20px;
}

.postBody :is(h1, h2, h3, h4, h5, h6) {
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--main-color);
}

.postBody a {
  color: var(--main-color);
  font-weight: 700;
  font-style: italic;
}
