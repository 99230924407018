.footer {
  background-color: var(--second-color);
  width: 100%;
  padding: 20px;
  margin-top: auto;
}
.footer .top-footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 30px;
}
.footer .top-footer a {
  height: 80px;
}
.footer .top-footer a img {
  height: 100%;
}
.top-footer .subs {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  gap: 20px;
}
.footer .top-footer .subs h4 {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: uppercase;
}

.top-footer .subs .mail {
  display: flex;
  flex: 1;
}

.top-footer .subs input.inp-email {
  flex: 1;
}
.top-footer .subs input.inp-email,
.top-footer .subs .inp-btn {
  padding: 0.8rem;
  background-color: #eeeeee2a;
  border: 0;
  outline: 0;
  color: #fff;
  font-size: 16px;
}

.footer .top-footer .subs .inp-btn {
  background-color: var(--main-color);
}

@media (max-width: 320px) {
  .top-footer .subs .mail {
    flex-direction: column;
  }

  .top-footer .subs .inp-email,
  .top-footer .subs .inp-btn {
    width: 100%;
  }
}

.bot-footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(90%, 250px), 1fr));
  gap: 1.5rem;
}
.bf-sec .st-cont {
  margin-bottom: 30px;
  text-align: left;
}
.bot-footer .sec-title {
  font-size: 20px;
  margin-bottom: 50px;
  text-align: left;
  color: white;
}
.bot-footer .sec-title::after {
  bottom: -5px;
}
.bf-sec p {
  color: #fff;
}
.bf-about ul {
  display: flex;
  margin-top: 1rem;
  gap: 10px;
}
.bf-about ul li a {
  font-size: 20px;
  display: grid;
  place-items: center;
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: #464646;
  padding: 10px;
  transition: 0.3s;
}
.bf-about ul li a.facebook:hover {
  background-color: #0866ff;
}
.bf-about ul li a.x:hover {
  background-color: #000;
}
.bf-about ul li a.instagram:hover {
  background-color: #5057ce;
}
.bf-about ul li a.youtube:hover {
  background-color: #f80100;
}
.bf-links ul li,
.bf-con ul li {
  color: #fff;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.bf-links ul li svg,
.bf-con ul li svg {
  margin-right: 10px;
}
.bf-links ul li a {
  color: #fff;
  font-size: 18px;
  transition: 0.3s;
  text-transform: capitalize;
}
.bf-links ul li a:hover {
  color: var(--main-color);
}
.bf-con ul li svg {
  color: #fff;
  background-color: var(--main-color);
  width: auto;
  height: auto;
  padding: 5px;
  border-radius: 50%;
}
.bf-art ul li {
  display: flex;
  margin-bottom: 20px;
}
.bf-art ul li a img {
  height: 100px;
  width: 100px;
  object-fit: cover;
}
.bf-art ul li .bf-pf {
  padding-left: 20px;
}
.bf-art ul li .bf-pf span {
  display: flex;
  align-items: center;
  color: #eee;
}
.bf-art ul li .bf-pf span svg {
  margin-right: 5px;
}
.bf-art ul li .bf-pf a {
  display: flex;
  align-items: center;
  color: var(--main-color);
  text-transform: capitalize;
}
.bf-art ul li .bf-pf a svg {
  margin-left: 10px;
}
.bf-art ul li .bf-pf a.bfpf-title {
  color: #fff;
}
@media (max-width: 664px) {
  .top-footer .subs {
    flex-wrap: wrap;
  }
  .top-footer a {
    display: none;
  }
  .top-footer div.subs {
    width: 100%;
    text-align: center;
  }
}
@media only screen and (max-width: 769px) {
  .top-footer a {
    display: none;
  }
  .top-footer div.subs {
    justify-content: center;
  }

  .bf-sec {
    text-align: center;
  }

  .bf-about ul,
  .bf-art ul li {
    justify-content: center;
  }

  .bf-con ul,
  .bf-links ul {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem 1.5rem;
    justify-content: space-evenly;
    margin-bottom: 0;
  }
}

.footer .title {
  margin-bottom: 30px;
  text-align: left;
  color: white;
}

.title h2 {
  font-size: 20px;
}

.title h2::after {
  bottom: -5px;
}

@media (max-width: 768px) {
  .footer .title {
    text-align: center;
  }

  .title h2::after {
    left: 50%;
    transform: translateX(-50%);
  }
}
