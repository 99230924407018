.post-gallery__title {
  color: var(--main-color);
  margin-bottom: 1rem;
}

.post-gallery {
  display: flex;
  justify-content: center;
  position: relative;
  width: min(500px, 90%);
  margin: auto;
  margin-bottom: 130px;
}
.post-gallery__wrapper {
  width: var(--size);
  height: var(--size);
  overflow: hidden;
}

.post-gallery__inner {
  display: flex;
  position: relative;
  width: max-content;
  transition: transform 0.5s ease;
}

.post-gallery__image {
  display: flex;
  justify-content: center;
  width: var(--size);
  height: var(--size);
}

.post-gallery__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-gallery__controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: var(--size);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.post-gallery__controls button {
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: none;
  background-image: radial-gradient(
    ellipse at left,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 70%
  );
  border: none;
  color: #fff;
  font-size: 1rem;
  line-height: 1px;
  opacity: 0.5;
  transition: all 0.3s ease;
}

.post-gallery__controls button:last-child {
  background-image: radial-gradient(
    ellipse at right,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 70%
  );
}

.post-gallery__controls button svg {
  transition: all 0.3s ease;
}

.post-gallery__controls button:hover {
  opacity: 0.7;
}

.post-gallery__controls button:hover svg {
  transform: scale(1.2);
}

.post-gallery__thumbs {
  margin-top: 10px;
  position: absolute;
  top: 100%;
  height: 120px;
  width: var(--size);
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.post-gallery__thumbs::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.post-gallery__thumbs::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.post-gallery__thumbs::-webkit-scrollbar-track {
  background: transparent;
}

.post-gallery__thumbs .post-gallery__thumbs-inner {
  display: flex;
  gap: 10px;
  width: max-content;
}

.post-gallery__thumbs img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  pointer-events: none;
}

.post-gallery__thumbs-inner div {
  flex: 1;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.3s ease;
}

.post-gallery__thumbs-inner div:hover {
  transform: scale(1.1);
}

.post-gallery__thumbs-inner .active-slide {
  border-color: var(--main-color);
}

.post-gallery__single-image {
  margin: auto;
  margin-bottom: 30px;
  width: min(500px, 90%);
  height: var(--size);
  overflow: hidden;
}
