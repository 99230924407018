.hero {
  background-image: var(--cover);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  position: relative;
}

.hero::before {
  content: "";
  background-color: rgba(255, 209, 123, 0.25);
  height: 100vh;
  height: 100dvh;
  width: 100%;
  position: absolute;
}

.hero-cont {
  position: relative;
  height: 100vh;
  height: 100dvh;
}
.hero-cont .hc-c {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding-left: 20px;
  border-left: 3px solid var(--main-color);
  color: #fff;
}

.hero-cont .hc-c.centered {
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0;
  padding: 0;
  color: #000;
}

.hero-cont .hc-c h1 {
  font-weight: 700;
  font-size: clamp(2rem, 10vw, 3rem);
  letter-spacing: 5px;
  text-transform: uppercase;
}
.hero-cont .hc-c p {
  font-weight: 300;
  margin-bottom: 20px;
  max-width: 50%;
  line-height: 1.7;
}

@media (max-width: 768px) {
  .hero-cont .hc-c {
    font-size: 18px;
    padding: 20px;
    border: 0;
    text-align: center;
  }

  .hero-cont .hc-c p {
    max-width: 100%;
    text-align: center;
  }
}

.hero-cont .hc-c a {
  display: inline-block;
  color: #fff;
  font-size: 18px;
  border: 1px solid #fff;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize;
  letter-spacing: 4px;
  transition: 0.3s;
  margin-bottom: 30px;
}
.hero-cont .hc-c a:hover {
  color: #fff;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
}
